/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  /* overflow-x: auto; overflow-x: overlay;*/
}

/*html { overflow: hidden!important; top: 0px!important; }*/
html {
  top: 0px !important;
}

:root {
  --header-color: #a7b6ce;
}

.md-chat-widget-icon-svg svg path {
  fill: #d0cc0e !important;
}

.md-chat-widget-btn-wrapper {
  margin: -5px 0;
}

.md-chat-widget-wrapper {
  bottom: 10px !important;
}

.mat-cell,
.mat-header-cell {
  word-wrap: initial;
  display: table-cell;
  padding: 0px 10px;
  line-break: unset;
  /* width: 100%; */
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

tr:nth-child(even) {
  background-color: #ebedf2;
}

.mat-row:hover .mat-cell {
  background: #dadae9;
}

th {
  /* background: #3f51b5; */
  background: #a7b6ce;
  color: #fff !important;
}

.mat-sort-header-arrow {
  color: #fff;
}

.mat-row,
.mat-header-row {
  display: table-row;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 5px 10px !important;
  height: 24px;
  line-height: 15px;
}

[class*=mat-column-] {
  padding: 0 7px !important;
}

.primary {
  background-color: #3f51b5 !important;
}

/* .styled-button button::before {
  transition: 0.2s ease-in-out;
  background-color: rgb(63, 81, 181, 0.9) !important;
}

.styled-button button:hover:before {
  width: 100%
} */
.mat-sort-header-arrow {
  opacity: 1 !important;
}

.mat-toolbar-multiple-rows {
  min-height: 50px !important;
}

.ups .file-input-button {
  font-size: 12px !important;
  padding: 0 15px !important;
  line-height: 24px !important;
}

.full .file-input-button {
  background: #3f51b5;
  border-radius: 15px;
  color: #fff !important;
}

.mat-snack-bar-container {
  z-index: 9999;
}

.barsenha {
  padding: 0 10px;
  width: 98% !important;
  margin: -15px 0 10px;
}

.show {
  cursor: pointer;
}

#over {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.32);
  color: #fff;
  position: fixed;
  z-index: 9999;
  display: none;
}

.circle {
  margin: 0 auto;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*css do alerta de login*/
.warning-snackbar {
  background: #f8d406;
  color: #000;
}

.success-snackbar {
  background: #04a504;
  color: #fff;
}

.error-snackbar {
  background: #b80514;
  color: #fff;
}

.error-snackbar .mat-simple-snackbar-action,
.success-snackbar .mat-simple-snackbar-action {
  color: #fff;
}

.submenu {
  padding-left: 50px !important;
  padding-right: 30px !important;
}

.mat-menu-panel {
  min-width: 400px !important;
  max-width: 550px !important;
  overflow: hidden !important;
}

input {
  width: 95% !important;
  height: 17px;
}

.app-content {
  background-color: #3f51b5;
  padding: 55px 15px 0px;
  /*height: 100%;
    overflow: hidden;
    min-height: 100vh;*/
  min-height: 100vh;
}

.mat-toolbar {
  background: #344290 !important;
}

.header-toolbar {
  background: #344290 !important;
  color: #fff;
  position: fixed;
  z-index: 1000;
}

.mat-raised-button {
  border-radius: 5px !important;
}

.title {
  color: #3f4651;
  text-align: center;
  padding: 5px;
  background: transparent;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 15px;
}

.icon-disable {
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 34px;
  margin: 2.5px;
  color: rgba(50, 64, 146, 0.362);
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f0eded93;
}

.icone:hover {
  cursor: pointer;
}

.expansion-icone {
  float: right;
  line-height: 32px;
  margin: 0 5px;
}

.back-icone {
  float: left;
  line-height: 32px;
  margin: 0 5px;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
  margin: 0 15px !important;
}

.mat-expansion-panel-header {
  padding: 0px !important;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0), 0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0) !important;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  display: flex;
  flex-grow: 1;
  margin-right: 0px !important;
}

.table thead th {
  vertical-align: middle !important;
  border-bottom: 1px solid #dee2e6;
}

td {
  display: table-cell;
  vertical-align: inherit !important;
}

.contain {
  width: calc(90% - 5px) !important;
  margin: -5px auto !important;
  display: flex;
}

.contain2 {
  width: 100% !important;
  margin: -5px auto !important;
  display: flex;
}

.column-1,
.column-2 {
  background: #fff;
  border-radius: 20px;
  padding: 15px;
  display: inline-block;
  width: 50%;
}

.column-1 {
  margin-right: 8px !important;
  margin-top: 0 !important;
}

.column-2 {
  margin-left: 8px !important;
  margin-top: 0 !important;
}

fieldset {
  border: 1px solid #ccc;
  border-radius: 10px;
}

legend {
  width: auto;
  padding: 10px 5px;
  font-size: 1.1rem;
}

.mat-dialog-container {
  border-radius: 10px !important;
}

.aviso {
  background-color: #f1eb42 !important;
  color: black;
  padding: 15px 50px !important;
  width: 90% !important;
  margin: 15px auto !important;
  border-radius: 10px !important;
}

.rectangle {
  position: relative;
  top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 150px;
  background-color: #fd4140;
  border: solid 1px #121621;
  color: #121621;
  margin: auto;
}

mwlResizable {
  box-sizing: border-box;
  /* required for the enableGhostResize option to work */
}

.resize-handle-top,
.resize-handle-bottom {
  position: absolute;
  height: 5px;
  cursor: row-resize;
  width: 100%;
}

.resize-handle-top {
  top: 0;
}

.resize-handle-bottom {
  bottom: 0;
}

.resize-handle-left,
.resize-handle-right {
  position: absolute;
  height: 100%;
  cursor: col-resize;
  width: 5px;
}

.resize-handle-left {
  left: 0;
}

.resize-handle-right {
  right: 0;
}

div [class*=col] .mat-form-field {
  width: 100%;
}

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0), 0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0) !important;
}

.right {
  /* float: right; */
  width: 100%;
  margin: 30px 0 30px 0 !important;
  text-align: right;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 7.5px;
}

.mat-form-field-wrapper {
  padding-bottom: 15px !important;
}

.card {
  background-color: #fff !important;
  border: none !important;
}

.export {
  padding: 0;
  font-size: 11px !important;
}

/* ===================== TABELA ======================== */
.tabela .input {
  width: 100% !important;
  padding: 15px;
}

.tabela {
  background: #fff;
  border-radius: 20px;
  width: 100% !important;
  margin: 10px auto !important;
  padding: 15px !important;
}

table tr:last-child td {
  border-bottom: 0 solid;
}

.mat-table {
  width: 100%;
}

.mat-table.resizing {
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: col-resize;
}

.mat-tab-label-active {
  background: #3f51b5;
  color: #ffffff;
}

.mat-tab-label {
  border: 1px solid #d3d5d7 !important;
  opacity: 1 !important;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.mat-table .mat-cell span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-table .mat-header-cell {
  position: relative;
}

.mat-table .mat-header-cell:not(:last-child) .resize-holder {
  cursor: col-resize;
  width: 20px;
  height: 100%;
  position: absolute;
  right: -10px;
  top: 0;
  z-index: 1;
}

.mat-table .mat-cell,
.mat-table .mat-header-cell {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-table .mat-cell:not(:nth-child(1)),
.mat-table .mat-header-cell:not(:nth-child(1)) {
  padding: 0 10px;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding: 7px !important;
}

.mat-column-CNPJ_Contribuinte,
.mat-column-CNPJ_Gestor {
  width: 145px;
}

.mat-column-Telefone,
.mat-column-CelularSMS {
  width: 130px;
}

.mat-vertical-stepper-header {
  border-radius: 10px;
  margin: 15px 15px 0;
}

.plan > .example-ripple-container {
  border-radius: 10px;
  padding: 10px;
  margin: 0 0 10px;
  text-align: center;
  cursor: pointer;
}

.plan > .example-ripple-container:hover {
  background-color: #3f51b5;
  color: #fff;
}

.progress {
  margin: 0 -15px;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 921px) {
  .column-1,
.column-2 {
    width: 100% !important;
    margin: 20px auto !important;
  }

  .contain,
.contain2 {
    width: 90% !important;
    margin: 20px auto !important;
    display: block;
  }
}
@media only screen and (max-width: 575px) {
  .mat-expansion-panel-body {
    padding: 0 0px 15px;
  }

  .mat-expansion-panel-content > .mat-expansion-panel-body {
    padding: 0 0 16px !important;
  }

  .exportar img {
    width: 20px;
    display: inline-block;
    margin: 0 11px;
  }

  .mat-h1,
.mat-headline,
.mat-typography h1 {
    margin: 0 15px !important;
    font-size: 17px;
  }

  .botoes {
    padding: 0;
    margin: 0;
  }

  .botoes button {
    margin-bottom: 15px !important;
  }

  .botoes > span {
    width: 100%;
    margin: 0 0 13px;
    text-align: center;
  }

  .example-ripple-container {
    cursor: pointer;
    text-align: center;
    width: 100% !important;
    height: 300px;
    display: inline-block;
    margin: 5px auto !important;
  }

  .right {
    margin: 0 0 30px !important;
    width: 100%;
  }

  .mat-form-field {
    width: 100% !important;
  }

  .mat-dialog-container {
    padding: 10px !important;
  }

  .mat-vertical-content {
    padding: 0 !important;
  }

  .mat-paginator-range-label {
    margin: 0px !important;
    letter-spacing: -1px;
  }

  .mat-paginator-page-size {
    flex-direction: column;
  }

  .mat-paginator-page-size-label {
    letter-spacing: -1px;
    font-size: 12px;
    line-height: 10px;
    margin: -5px 0 !important;
  }

  .mat-button-wrapper {
    font-size: 10px !important;
  }

  .mat-vertical-content-container {
    margin-left: 15px !important;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
    padding: 24px 5px !important;
  }

  .botoes {
    font-size: 12px !important;
  }

  .mat-horizontal-stepper-header {
    display: flex;
    height: 35px;
    overflow: hidden;
    align-items: center;
    padding: 0 10px !important;
    width: 100%;
  }
}
@media only screen and (max-width: 280px) {
  .mat-button-wrapper {
    font-size: 10px !important;
  }

  .mat-toolbar [class*=col] {
    padding: 0px !important;
  }

  .impostograma {
    margin-left: -25px;
  }

  .mat-paginator-range-label {
    margin: 0px !important;
    letter-spacing: -1px;
  }

  fieldset {
    width: 100%;
    padding: 0px !important;
    margin: 15px 0 20px;
  }

  .mat-card {
    width: 100% !important;
  }

  legend {
    font-size: 1rem;
  }

  .mat-form-field {
    width: 100% !important;
  }

  .mat-vertical-content {
    padding: 0 !important;
  }

  .mat-paginator-page-size-label {
    letter-spacing: -1px;
    font-size: 12px;
    line-height: 10px;
    margin: -5px 0 !important;
  }

  .mat-dialog-container {
    padding: 10px !important;
  }

  .mat-vertical-content-container {
    margin-left: 15px !important;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
    padding: 24px 5px !important;
  }

  .botoes {
    font-size: 12px !important;
    padding: 0;
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  margin-right: 10px;
}

::-webkit-scrollbar-corner {
  border: none;
  background: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border: none;
}

::-webkit-scrollbar-thumb {
  background-color: #3d3c42;
  border-radius: 3px;
  cursor: move;
}

.message-snackbar {
  background: #fff;
  color: #000;
}

.message-snackbar .mat-simple-snackbar-action,
.warning-snackbar .mat-simple-snackbar-action {
  color: #000;
}

.success-progress .mat-progress-bar-fill::after {
  background-color: #0dbc0d !important;
}

.yellow-progress .mat-progress-bar-fill::after {
  background-color: #ffd700 !important;
}

.orange-progress .mat-progress-bar-fill::after {
  background-color: #ffa500 !important;
}

.loading-progress .mat-progress-bar-fill::after {
  background-color: #ffa91d !important;
}

[mat-raised-button][color=primary] {
  transition: 0.2s ease-in-out !important;
}

[mat-raised-button][color=primary]:hover {
  transform: scale(1.01) !important;
  background-color: rgba(63, 81, 181, 0.8) !important;
}

.transform-field {
  transition: 0.2s ease-in-out !important;
}

.transform-field:hover {
  transform: scale(1.02) !important;
}